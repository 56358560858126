import { graphql } from '~/contentful/graphql';

export const SpotlightSlideshow_SpotlightSlideshowFragment = graphql(`
  fragment SpotlightSlideshow_SpotlightSlideshowFragment on SpotlightSlideshow {
    headline
    subheadline
    imagesCollection {
      items {
        ...SpotlightSlideshowSlide_AssetFragment
      }
    }
  }
`);

export const SpotlightSlideshowSlide_AssetFragment = graphql(`
  fragment SpotlightSlideshowSlide_AssetFragment on Asset {
    ...Image_AssetFragment
    description
  }
`);
