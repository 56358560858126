import type { Primitive } from 'type-fest';

// https://github.com/dotansimha/graphql-code-generator/discussions/8554#discussioncomment-6766027
type UnmaskedFragment<TFrag> = TFrag extends {
  ' $fragmentRefs'?: infer FragmentRefs;
}
  ? FragmentRefs extends Record<string, infer FragmentRef>
    ? UnmaskedFragment<FragmentRef & Omit<TFrag, ' $fragmentRefs' | ' $fragmentName'>>
    : never
  : TFrag extends Primitive
    ? TFrag
    : { [key in keyof TFrag]: UnmaskedFragment<TFrag[key]> };

/**
 * Unmasks the given fragment into an {@link UnmaskedFragment} where its
 * properties can be directly queried without having to call
 * {@link getFragmentData}.
 *
 * This should only be used in special cases where the fragment type is unknown
 * and cannot be determined without knowing the internals of the fragment.
 */
export function unmaskFragment<TFrag>(fragment: TFrag): UnmaskedFragment<TFrag> {
  return fragment as UnmaskedFragment<TFrag>;
}
