import { graphql } from '~/contentful/graphql';

export const SpotlightVideo_SpotlightVideoFragment = graphql(`
  fragment SpotlightVideo_SpotlightVideoFragment on SpotlightVideo {
    headline
    subheadline
    video {
      src: url
    }
    videoUrl
    videoCaptions {
      src: url
    }
    previewVideo {
      src: url
    }
    previewVideoUrl
    previewStaticImage {
      ...Image_AssetFragment
    }
    ctaLabel
  }
`);
