import { forwardRef } from 'react';

import YouTube, { type YouTubePlayer, type YouTubeProps } from 'react-youtube';

interface SpotlightVideoProps extends React.ComponentPropsWithRef<'video'> {
  videoId?: string;
  isPreview?: boolean;
  isAccordion?: boolean;
  onReady?: () => void;
  // This is required for preview videos since passing in a ref does not work
  getYouTubeVideoRef?: (ref: YouTubePlayer) => void;
}

function SpotlightYouTubeVideoRender(
  {
    videoId,
    autoPlay = true,
    controls = false,
    loop = true,
    muted = true,
    onReady,
    getYouTubeVideoRef,
    ...props
  }: SpotlightVideoProps,
  ref: React.ForwardedRef<YouTubePlayer>,
) {
  const opts: YouTubeProps['opts'] = {
    playerVars: {
      autoplay: autoPlay ? 1 : 0,
      controls: controls ? 1 : 0,
      mute: muted ? 1 : 0,
      loop: loop ? 1 : 0,
      modestbranding: 1,
      showinfo: 0,
      enablejsapi: 1,
      autohide: 1,
      rel: 0,
    },
  };

  return (
    <YouTube
      ref={youTubeRef => {
        const youTubePlayer = youTubeRef?.getInternalPlayer();
        if (typeof ref === 'function') {
          ref(youTubePlayer);
        } else if (ref) {
          ref.current = youTubePlayer;
        }
      }}
      className={props.className}
      videoId={videoId}
      opts={opts}
      onReady={
        onReady ||
        (getYouTubeVideoRef &&
          (event => {
            getYouTubeVideoRef(event.target);
          }))
      }
    />
  );
}

export const SpotlightYouTubeVideo = forwardRef<YouTubePlayer, SpotlightVideoProps>(
  SpotlightYouTubeVideoRender,
);
