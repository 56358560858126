'use client';

import dynamic from 'next/dynamic';
import { type MutableRefObject, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useInView } from 'framer-motion';
import { type YouTubePlayer } from 'react-youtube';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import {
  type SpotlightSlideshow_SpotlightSlideshowFragmentFragment,
  type SpotlightVideo_SpotlightVideoFragmentFragment,
} from '~/contentful/graphql/_generated/graphql';
import { unmaskFragment } from '~/contentful/graphql/unmaskFragment';
import { flattenCollection } from '~/contentful/utils/collection';
import CameraIcon from '~/ui/assets/icons/camera.svg';
import PauseIcon from '~/ui/assets/icons/pause.svg';
import PlayIcon from '~/ui/assets/icons/play.svg';
import { AnalyticsSpotlight, Event, trackEvent } from '~/ui/components/analytics';
import { FullBleedCTAScope, FullBleedCTATrigger } from '~/ui/components/fullBleedCta';
import { Column, Grid } from '~/ui/components/grid';
import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Image } from '~/ui/modules/image';
import {
  SpotlightSlideshowSlide_AssetFragment,
  SpotlightSlideshow_SpotlightSlideshowFragment,
} from '~/ui/modules/spotlight/slideshow/query';
import { SpotlightVideo_SpotlightVideoFragment } from '~/ui/modules/spotlight/video/query';
import { SpotlightYouTubeVideo } from '~/ui/modules/spotlight/video/video';
import videoStyles from '~/ui/styles/components/video.module.scss';
import { Breakpoint } from '~/ui/styles/grid';
import typographyStyles from '~/ui/styles/typography.module.scss';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonSize, ButtonType } from '~/v1/components/button/button.interface';
import { Modal } from '~/v1/components/modal/modal';
import { useOnScreen } from '~/v1/hooks/useOnScreen';

import styles from './spotlight.module.scss';

const SpotlightSlideshow = dynamic(() =>
  import('./slideshow/slideshow').then(mod => mod.SpotlightSlideshow),
);

interface SpotlightProps {
  data:
    | FragmentType<typeof SpotlightSlideshow_SpotlightSlideshowFragment>
    | FragmentType<typeof SpotlightVideo_SpotlightVideoFragment>;

  className?: string;
  priority?: boolean;
}

export function Spotlight({ data, className, priority }: SpotlightProps): React.ReactElement {
  const [isBackgroundPaused, setIsBackgroundPaused] = useState<boolean>(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const fullVideoRef = useRef<HTMLVideoElement>(null);
  const fullYouTubeVideoRef = useRef<YouTubePlayer>(null);
  const [size, setSize] = useState<string>();

  const previewVideoRef = useRef<HTMLVideoElement>(null);
  // Preview YouTube videos must use state and not a ref. If using a ref,
  // the video is unable to initially pause and throws an exception
  const [previewYouTubeVideo, setPreviewYouTubeVideo] = useState<YouTubePlayer>();
  const isPreviewVideoInView = useInView(previewVideoRef);
  // This wrapper is necessary since the ref cannot be created on the
  // YouTubePlayer itself
  const previewYouTubeVideoWrapperRef = useRef<HTMLDivElement>(null);
  const isPreviewYouTubeVideoInView = useInView(previewYouTubeVideoWrapperRef);

  const breakpoint = useBreakpoint();
  const { inView, ref } = useOnScreen();

  useEffect(() => {
    calculateSize();
  });

  useEffect(() => {
    inView && setShouldAnimate(true);
  }, [inView]);

  useEffect(() => {
    if (isPreviewVideoInView) {
      previewVideoRef.current?.play();
    } else {
      previewVideoRef.current?.pause();
    }
  }, [isPreviewVideoInView]);

  useEffect(() => {
    if (isPreviewYouTubeVideoInView) {
      previewYouTubeVideo?.playVideo();
    } else {
      try {
        previewYouTubeVideo?.pauseVideo();
      } catch (e) {
        // No-op. An exception occurs here on page load, but it does not affect
        // the ability to pause the video. Instead of letting it crash the page,
        // ignore the exception and continue.
      }
    }
  }, [previewYouTubeVideo, isPreviewYouTubeVideoInView]);

  const unmaskedData = unmaskFragment(data);
  let spotlightSlideshow: SpotlightSlideshow_SpotlightSlideshowFragmentFragment | undefined;
  let spotlightVideo: SpotlightVideo_SpotlightVideoFragmentFragment | undefined;
  switch (unmaskedData.__typename) {
    case 'SpotlightSlideshow':
      spotlightSlideshow = getFragmentData(
        SpotlightSlideshow_SpotlightSlideshowFragment,
        data as FragmentType<typeof SpotlightSlideshow_SpotlightSlideshowFragment>,
      );

      break;

    case 'SpotlightVideo':
      spotlightVideo = getFragmentData(
        SpotlightVideo_SpotlightVideoFragment,
        data as FragmentType<typeof SpotlightVideo_SpotlightVideoFragment>,
      );

      break;
  }

  const videoUrl = spotlightVideo?.video?.src ?? undefined;
  const videoId = getVideoId(spotlightVideo?.videoUrl ?? '');
  const captionsUrl = spotlightVideo?.videoCaptions?.src ?? undefined;
  const previewVideoUrl = spotlightVideo?.previewVideo?.src ?? undefined;

  const images = spotlightSlideshow ? flattenCollection(spotlightSlideshow.imagesCollection) : [];

  const headline = spotlightSlideshow?.headline ?? spotlightVideo?.headline ?? '';
  const eyebrow = spotlightSlideshow?.subheadline ?? spotlightVideo?.subheadline ?? '';
  const Headline = Breakpoint.SM !== breakpoint ? 'h2' : 'h3';
  const showToggleButton = previewVideoUrl || (!spotlightVideo?.previewStaticImage && !images);

  const buttonLabel =
    spotlightVideo && spotlightVideo.ctaLabel ? spotlightVideo.ctaLabel : 'View gallery';
  const ButtonIcon = spotlightVideo ? PlayIcon : CameraIcon;

  const calculateSize = async () => {
    const size = spotlightVideo?.video
      ? formatDuration(fullVideoRef.current?.duration)
      : spotlightVideo?.videoUrl
        ? formatDuration(await fullYouTubeVideoRef.current?.getDuration())
        : images
          ? `${images.length} photo${images.length > 1 ? 's' : ''}`
          : '';

    setSize(size);
  };

  const toggleBackground = () => {
    setIsBackgroundPaused(!isBackgroundPaused);

    if (isBackgroundPaused) {
      previewVideoRef.current?.play();
      previewYouTubeVideo?.playVideo();
    } else {
      previewVideoRef.current?.pause();
      previewYouTubeVideo?.pauseVideo();
    }
  };

  const openModal = () => {
    trackEvent(Event.SPOTLIGHT_CLICK, {
      spotlightType: videoUrl || videoId ? AnalyticsSpotlight.VIDEO : AnalyticsSpotlight.SLIDESHOW,
      spotlightTitle: headline,
    });

    setIsModalOpen(true);

    if (fullVideoRef.current) {
      fullVideoRef.current.currentTime = 0;
      fullVideoRef.current.play();
    }

    if (fullYouTubeVideoRef.current) {
      fullYouTubeVideoRef.current.seekTo(0);
      fullYouTubeVideoRef.current.playVideo();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);

    if (fullVideoRef.current) {
      fullVideoRef.current.pause();
      fullVideoRef.current.currentTime = 0;
    }

    if (fullYouTubeVideoRef.current) {
      fullYouTubeVideoRef.current.pauseVideo();
      fullYouTubeVideoRef.current.seekTo(0);
    }
  };

  return (
    <div className={cx(styles.spotlight, className)}>
      <div className={styles.spotlightInner} ref={ref as MutableRefObject<HTMLDivElement | null>}>
        {previewVideoUrl ? (
          <video
            ref={previewVideoRef}
            className={cx(videoStyles.videoWrapper, videoStyles.previewVideo)}
            src={previewVideoUrl}
            autoPlay
            controls={false}
            loop
            muted
            playsInline
            disablePictureInPicture
            disableRemotePlayback
          >
            <track kind="captions" />
          </video>
        ) : spotlightVideo?.previewStaticImage ? (
          <Image
            image={spotlightVideo.previewStaticImage}
            aspectRatio="5/4"
            className={cx(styles.image, { [styles.imageZoomIn]: shouldAnimate })}
            priority={priority}
            colSpan={{
              [Breakpoint.SM]: 5,
              [Breakpoint.MD]: 7,
              [Breakpoint.LG]: 9,
            }}
          />
        ) : videoUrl ? (
          <video
            ref={previewVideoRef}
            className={cx(videoStyles.videoWrapper, videoStyles.previewVideo)}
            src={videoUrl}
            autoPlay
            controls={false}
            loop
            muted
          >
            <track kind="captions" />
          </video>
        ) : (
          videoId && (
            <div ref={previewYouTubeVideoWrapperRef}>
              <SpotlightYouTubeVideo
                className={cx(videoStyles.videoWrapper, videoStyles.previewVideo)}
                videoId={videoId}
                isPreview
                getYouTubeVideoRef={setPreviewYouTubeVideo}
              />
            </div>
          )
        )}

        {images[0] && (
          <Image
            image={getFragmentData(SpotlightSlideshowSlide_AssetFragment, images[0])}
            aspectRatio="5/4"
            className={cx(styles.image, { [styles.imageZoomIn]: shouldAnimate })}
            priority={priority}
            colSpan={{
              [Breakpoint.SM]: 5,
              [Breakpoint.MD]: 7,
              [Breakpoint.LG]: 9,
            }}
          />
        )}
        <div className={styles.gradient} />
        {showToggleButton && (
          <Button
            isTransparent
            size={ButtonSize.Icon}
            className={styles.button}
            type={ButtonType.Secondary}
            mode={ButtonMode.Light}
            aria-label={isBackgroundPaused ? 'Play background' : 'Pause background'}
            onClick={toggleBackground}
          >
            {isBackgroundPaused ? (
              <PlayIcon className={styles.iconPlayPause} aria-hidden />
            ) : (
              <PauseIcon className={styles.iconPlayPause} aria-hidden />
            )}
          </Button>
        )}
      </div>

      <FullBleedCTAScope>
        <Grid render={<article />} aria-label={headline} className={styles.content}>
          <Column sm={5} md={5} lg={5} offsetLeft={{ lg: 1 }}>
            {eyebrow && <div className={styles.eyebrow}>{eyebrow}</div>}
            {headline && (
              <Headline
                className={cx('truncate-3', typographyStyles.modulesTitle, styles.headline)}
              >
                {headline}
              </Headline>
            )}
            <FullBleedCTATrigger>
              <Button
                type={ButtonType.Primary}
                mode={Breakpoint.SM === breakpoint ? ButtonMode.Light : ButtonMode.Dark}
                ariaLabel={buttonLabel}
                onClick={openModal}
              >
                {ButtonIcon && <ButtonIcon className={styles.icon} />}
                {buttonLabel}
                <span
                  aria-label={videoUrl || videoId ? `Total time ${size}` : `${size} in gallery`}
                  className={styles.buttonInfo}
                >
                  {size}
                </span>
              </Button>
            </FullBleedCTATrigger>
            <Modal
              isModalOpen={isModalOpen}
              className={cx({ [styles.modalVideo]: videoId })}
              closeModal={closeModal}
            >
              {(videoUrl && (
                <video
                  ref={fullVideoRef}
                  className={videoStyles.videoWrapper}
                  src={videoUrl}
                  autoPlay={isModalOpen}
                  controls
                  loop={false}
                  muted={false}
                  preload="metadata"
                  onLoadedMetadata={() => calculateSize()}
                >
                  {captionsUrl && (
                    <track src={captionsUrl} srcLang="en" label="English" kind="captions" default />
                  )}
                  <track kind="captions" />
                </video>
              )) ||
                (videoId && (
                  <SpotlightYouTubeVideo
                    ref={fullYouTubeVideoRef}
                    className={videoStyles.videoWrapper}
                    videoId={videoId}
                    autoPlay={isModalOpen}
                    controls
                    loop={false}
                    muted={false}
                    onReady={calculateSize}
                  />
                ))}
              {images.length > 0 && (
                <SpotlightSlideshow
                  data={
                    spotlightSlideshow as FragmentType<
                      typeof SpotlightSlideshow_SpotlightSlideshowFragment
                    >
                  }
                />
              )}
            </Modal>
          </Column>
        </Grid>
      </FullBleedCTAScope>
    </div>
  );
}

function getVideoId(url: string): string {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : '';
}

function formatDuration(duration?: number): string {
  if (duration) {
    const formattedDurationHours = new Date(duration * 1000).toISOString().slice(11, 19);
    const formattedDurationMinutes = new Date(duration * 1000).toISOString().slice(14, 19);

    if (formattedDurationHours.slice(0, 2) === '00') {
      return formattedDurationMinutes;
    }
    return formattedDurationHours;
  }

  return '';
}
